import React, { createContext, useContext, useEffect, useState } from 'react'
import {
	getEmployeeWorkItems,
	assignWorkItem as assignWork,
	getUnassignedWorkItems,
	unassignWorkItem as unassignWork,
} from '../../services/workItemService'
import { Employee } from '../../types/Employee/Employee'
import { WorkItem } from '../../types/Work/WorkItem'
import { useTokenClaims } from '../../security/useTokenClaims'
interface AssignWorkContextType {
	directReports: Employee[] | undefined
	workItems: WorkItem[] | undefined
	unassignWorkItem: (workItem: WorkItem) => Promise<boolean>
	assignWorkItem: (workItem: WorkItem, employeeId: number) => Promise<boolean>
	loadData: (newId: number | undefined, unassignPage?: boolean) => void
}

const AssignWorkContext = createContext<AssignWorkContextType | null>(null)

const useAssignWorkContext = (): AssignWorkContextType => {
	const assignWorkContextState = useContext(AssignWorkContext)
	if (assignWorkContextState === null) {
		throw new Error(
			'AssignWorkContext not found. Try wrapping a parent component with <AssignWorkContext.Provider>.',
		)
	}
	return assignWorkContextState
}

type AssignWorkProviderProps = {
	children?: React.ReactNode
}

const AssignWorkProvider = ({ children }: AssignWorkProviderProps) => {
	const [directReports, setDirectReports] = useState<Employee[] | undefined>([])
	const [workItems, setWorkItems] = useState<WorkItem[]>()
	const [lastId, setLastId] = useState<number | undefined>()
	const { id, role } = useTokenClaims()

	const unassignWorkItem = async (workItem: WorkItem) => {
		const result = await unassignWork(id, workItem.id)
		await loadData(lastId)
		return result
	}

	const assignWorkItem = async (workItem: WorkItem, employeeId: number): Promise<boolean> => {
		const result = await assignWork(workItem.id, employeeId)
		await loadData(lastId)
		return result
	}

	const loadData = async (newId: number | undefined, unassignPage?: boolean) => {
		const idParam: number | undefined = unassignPage ? undefined : newId ? newId : lastId //if we are on the unassign page, make this undefined, else use the passed in value or the value set when we navigated here
		setLastId(idParam) //set id for future use on this page
		if (role.title === 'ProjectControls' && !idParam) {
			getUnassignedWorkItems()
				.then((result) => {
					setWorkItems(result)
				})
				.catch((err) => {
					console.log(err)
				})
		}
		getEmployeeWorkItems(true, idParam ? idParam : id)
			.then((result) => {
				// set both if not pcm. if pcm, only set direct reports
				if (role.title !== 'ProjectControls' || idParam) setWorkItems(result.employee.workItems)
				setDirectReports(result.directReports)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	return (
		<AssignWorkContext.Provider
			value={{ directReports, workItems, unassignWorkItem, assignWorkItem, loadData }}
		>
			{children}
		</AssignWorkContext.Provider>
	)
}

export { useAssignWorkContext, AssignWorkProvider }
